import "./styles.sass";
import Chocolat from 'chocolat'

// Footer back to top
const backToTop = () =>
  $('.to-top').click(function(event) {
    event.preventDefault();
    return $('html, body').animate({ scrollTop: 0 }, 350);
  })
;

// Video Overlay
const toggleVideoOverlay = () =>
  $('.modal-trigger').click(function(event) {
    event.preventDefault();
    $('body').toggleClass('modal-active');
    $('html').toggleClass('modal-active');
    $('.modal').toggleClass('active');
    return $('video').get(0).play();
  })
;

// Close video
const closeVideoOverlay = () =>
  $('.modal').click(function(event) {
    const videoClicked = $(event.target).hasClass('featured-video') || $(event.target).parents().hasClass('featured-video');
    console.log(videoClicked);
    if (!videoClicked) {
      // Do your stuff
      $(this).removeClass('active');
      $('body').removeClass('modal-active');
      $('html').removeClass('modal-active');
      return $('video').get(0).pause();
    }
  })
;

$(function() {
  $('body').removeClass('no-js').addClass('js');

  $('.gallery').Chocolat({
      imageSize: 'contain'
  });

  const $playButton = $('.modal-trigger')
  const $featuredImage = $('.featured-image')

  $playButton.hover((e) => {
    $featuredImage.css({opacity: 1})
  }, () => {
    $featuredImage.css({opacity: .9})
  })
    
  backToTop();
  toggleVideoOverlay();
  return closeVideoOverlay();
    
});